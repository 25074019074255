import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from '@pancakeswap-libs/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: HVD_Comic_Serif_Pro;
    src: url(./fonts/HVD_Comic_Serif_Pro.otf);
  }
  @font-face {
    font-family: Sofia_Pro_Regular;
    src: url(./fonts/Sofia_Pro_Regular.otf);
  }
  @font-face {
    font-family: Sofia_Pro_Semi_Bold;
    src: url(./fonts/Sofia_Pro_Semi_Bold.otf);
  }
  * {
    font-family: Sofia_Pro_Regular;
  }
  body {
    background: url(../images/bg.png) ${({ theme }) => theme.colors.background} no-repeat fixed center center;
    background-size:cover;

    img {
      height: auto;
      max-width: 100%;
    }
  }
  *::-webkit-scrollbar {
    width: 5px;
  }
  *::-webkit-scrollbar-thumb {
    background: #ff841a;
    border-radius: 8px;
  }
  .hvd_comic_serif,
  .hvd_comicfont div {
    font-family: HVD_Comic_Serif_Pro;
    font-weight: 400;
    line-height: 1.1;
  }
  h2.hvd_comicfont {
    line-height: 0 !important;
    margin: 0;
  }
  .dash_subheading {
    font-size: 20px;
  }
  .white_box {    
    box-shadow: -4px 5px 10px 2px rgb(0 0 0 / 20%);
  }
  .vision_card_panel {
    max-width: 600px;
    margin: 10px auto 0;
    text-align: center;
    display: inline-block;
    width: 100%;
  }
  .vision_card_panel > div{
    width: 100%;
  }
  .menulink  div {
    font-size: 18px;
    font-family: Sofia_Pro_Semi_Bold !important;
    text-transform: uppercase;
  }
  div[role="button"] a {
    text-transform: uppercase;
    font-size: 15px;
    font-family: Sofia_Pro_Semi_Bold !important;
  }
  button div {
    font-family: "HVD_Comic_Serif_Pro";
    font-weight: 400 !important;
    font-size: 18px !important;
    color: #bd8a3e !important;
  }
  button {
    font-family: HVD_Comic_Serif_Pro !important;
    font-weight: 400 !important;
  }
  .header_btn {
    padding: 22px 40px;
    font-size: 18px;
    box-shadow: rgb(14 14 44 / 40%) 0px -1px 0px 0px inset !important;
    height: 45px !important;
  }
  .toggle_wrapper > div:nth-child(2) {
    background-color: ${({ theme }) => theme.colors.card};
    border-radius: 30px;
  }
  .toggle_wrapper > div div:first-child {
    background-color: ${({ theme }) => theme.colors.card};
  }
  .toggle_wrapper > div div:first-child div {
    background-color: #991d25;
  }
  button.farms_unlock_btn {
    width: 200px;
    height: 48px;
  }
  button.toggle_btn {
    box-shadow: none !important;
  }
  button {
    box-shadow: none !important;
  }
  @media screen and (max-width: 559px) {
    .toggle_wrapper {
      display: block !important;
      text-align: center;
    }
    .toggle_wrapper > div {
      margin-bottom: 15px;
    }
  }
`

export default GlobalStyle
