import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    risk: 5,
    lpSymbol: 'CHOCO-BUSD LP',
    lpAddresses: {
      97: '0x208Df691256FD7454BF9c6B8f7D234775F278773',
      56: '0x6973a5c2c8CbfaeBaC5fd4a7064Ec713F7b5EF34',
    },
    tokenSymbol: 'CHOCO',
    tokenAddresses: {
      97: '0xb894aCb5CfB69235bF1F5d839f7f34F7C9d6fF77',
      56: '0xdbae1687a8f5773b76306d99594623b255180830',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 1,
    risk: 5,
    lpSymbol: 'CHOCO-BNB LP',
    lpAddresses: {
      97: '0xbF7A1fB6Fd1226f3746277E1604cbD7Ae6aCec8D',
      56: '0x6736F40ba1F1E0EEf74b4308562687650bCE2d16',
    },
    tokenSymbol: 'CHOCO',
    tokenAddresses: {
      97: '0xb894aCb5CfB69235bF1F5d839f7f34F7C9d6fF77',
      56: '0xdbae1687a8f5773b76306d99594623b255180830',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 2,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'CHOCO',
    lpAddresses: {
      97: '0x208Df691256FD7454BF9c6B8f7D234775F278773',
      56: '0x6973a5c2c8CbfaeBaC5fd4a7064Ec713F7b5EF34', // CAKE-BUSD LP
    },
    tokenSymbol: 'CHOCO',
    tokenAddresses: {
      97: '0xb894aCb5CfB69235bF1F5d839f7f34F7C9d6fF77',
      56: '0xdbae1687a8f5773b76306d99594623b255180830',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 3,
    risk: 5,
    lpSymbol: 'CHOCO-ETH LP',
    lpAddresses: {
      97: '0xbF7A1fB6Fd1226f3746277E1604cbD7Ae6aCec8D',
      56: '0xAB3c79F4688965B51edBEF5247a2122f7d19838f',
    },
    tokenSymbol: 'ETH',
    tokenAddresses: {
      97: '0xf88ff3B6D22be2a28227968d9fbc6346ba6A2d61',
      56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
]

export default farms
