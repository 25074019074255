export default {
  cake: {
    56: '0xdbae1687a8f5773b76306d99594623b255180830',
    97: '0xb894aCb5CfB69235bF1F5d839f7f34F7C9d6fF77',
  },
  masterChef: {
    56: '0xeA17CEf9aAF490F93D0F7c0850dBB48f2C35C2ff',
    97: '0x65a6d5946d30911Fc0f2bca4eF4752587114fe65',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '0xBaE36c04b80FE01bb1611160B199fACB7E3CdC27',
  },
  eth: {
    56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    97: '0xf88ff3B6D22be2a28227968d9fbc6346ba6A2d61',
  },
  lottery: {
    56: '',
    97: '',
  },
  lotteryNFT: {
    56: '',
    97: '',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '0x8CE7720fD183AeC96b676FD8250724b05b0d7a6F',
  },
}
